<template>
  <v-container>
    <v-row>
      <h4 class="title">List of Communities</h4>
    </v-row>
    <v-btn fab small color="accent" to="edit/">
      <v-icon>add</v-icon>
    </v-btn>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="communities"
          :items-per-page="15"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div style="min-width: 90px">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <div style="min-width: 180px">
              {{ item.title }}
            </div>
          </template>
          <template v-slot:[`item.external_link`]="{ item }">
            <a :href="item.external_link" target="_blank">{{
              item.external_link
            }}</a>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex">
              <v-btn icon color="green" :to="'edit/' + item.id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon color="red" @click="onDelete(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteAlert" persistent max-width="360">
      <v-card>
        <v-card-title>Warning</v-card-title>
        <v-card-text
          >Are you sure you want to delete this community?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="deleteAlert = false">
            No
          </v-btn>
          <v-btn color="red" class="white--text" @click="deleteCommunity">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      headers: [
        { text: 'Name', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'External Link', value: 'external_link' },
        { text: 'Description', value: 'description' },
        { text: 'Action', value: 'action', sortable: false }
      ],
      communities: [],
      currentCommunityID: null,
      deleteAlert: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      communityList: 'communityList'
    })
  },
  async mounted() {
    this.getCommunities()
  },
  methods: {
    onDelete(communityID) {
      this.currentCommunityID = communityID
      this.deleteAlert = true
    },
    deleteCommunity() {
      this.deleteAlert = false
      const that = this
      if (!this.currentCommunityID) return
      this.$store
        .dispatch('deleteCommunity', this.currentCommunityID)
        .then(() => {
          that.getCommunities()
        })
    },
    async getCommunities() {
      await this.$store.dispatch('getCommunities')
      this.communities = this.communityList
      console.log(this.communities, 'communities')
    }
  }
}
</script>
