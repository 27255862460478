var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('h4',{staticClass:"title"},[_vm._v("List of Communities")])]),_c('v-btn',{attrs:{"fab":"","small":"","color":"accent","to":"edit/"}},[_c('v-icon',[_vm._v("add")])],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.communities,"items-per-page":15},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"90px"}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"180px"}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])]}},{key:"item.external_link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.external_link,"target":"_blank"}},[_vm._v(_vm._s(item.external_link))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"green","to":'edit/' + item.id}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onDelete(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"360"},model:{value:(_vm.deleteAlert),callback:function ($$v) {_vm.deleteAlert=$$v},expression:"deleteAlert"}},[_c('v-card',[_c('v-card-title',[_vm._v("Warning")]),_c('v-card-text',[_vm._v("Are you sure you want to delete this community?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.deleteAlert = false}}},[_vm._v("\n          No\n        ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"red"},on:{"click":_vm.deleteCommunity}},[_vm._v("\n          Yes\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }